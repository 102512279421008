<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="primary">mdi-account-multiple-check</v-icon> Events
                 <v-spacer/>
                 <span style="width: 290px;">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                 </span>
                 <v-divider vertical inset class="ml-5 mr-2"/>
                        <v-btn text small color="success"  @click="event={}, state='new', form=true"><v-icon small left color="success">mdi-plus</v-icon> New</v-btn>
               </v-toolbar>
               <v-card-text>
                 
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :search="search"
                    :items="events"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                              <v-icon  color="warning" @click="event=item, state='update', form=true">mdi-pencil-box</v-icon>
                              <v-icon  color="info" @click="$router.push('/event-entry/' + item.id).catch(err => {})">mdi-open-in-new</v-icon>
                            </td>  
                            <td> {{ item.name }}</td>
                          <td>{{ item.venue }}</td>
                            <td>{{ item.event_date | moment("YYYY-MMM-DD") }}</td>
                            <td>{{ item.percentage }}</td>
                            <td>{{ item.created_at | moment("YYYY-MM-DD hh:mm A") }}</td>
                             <td :class="STATUS_COLOR(item.status)">{{ item.status }}</td>

                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
         
    </v-row>
    </v-container>
    <va-event-form :show="form" :event_id="event.id" :state="state" @DialogEvent="formEvent" />
    <va-confirm :show="confirm" cancelbtn="Not Now" okbtn="REMOVE" :message="'Would you like remove this event '+eventname+'?'" @ConfirmEvent="confirmEvent" />
  </div>
</template>
<script>

import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        eventname:'',
        confirm: false,
        trans_dailog: false,
        areaform: false,
        state: 'new',
        event: {},
        search:'',
        events:[],
        hash: null,
        headers: [
         { text: ''},
            { text: 'Event', value:"name"},
            { text: 'Venue', value:"venue"},
            { text: 'Date', value:"event_date"},
            { text: 'Percentage', value:"percentage"},
            { text: 'created', value:"created_at"},
            { text: 'Status', value:"status"}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getItems()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      confirmEvent(e) {
        console.log(e)
        this.confirm = false
        if(e=='ok') {
          this.remove()
        }
      },
      remove() {
        this.event.status = "Remove"
        this.$http.post("event/update", this.event).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.getItems()
        }).catch(e => {
        console.log(e)
        })
      },
      afEvent() {
        this.areaform = false
        this.getAreas()
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.event = data.event
              this.Save()
          }
      },

      getItems: function() { 
        this.$http.get("event/list").then(response => {
            response.data.items != null?this.events = response.data.items:this.events =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
    
      Save: function() {
        let method = 'event/add'
        this.state != 'new'?method='event/update':method = 'event/add'
        this.event.name = this.event.name.trim()
        this.event.num_fights = this.$IsNum(this.event.num_fights)
        if(this.state == 'new') { 
          this.event.event_key = this.event.name+'-'+this.event.date+'-'+this.event.venue
           let event_key = this.event.name+'-'+this.event.venue
           this.event.event_key = event_key.replace(/\s/g, "-")
        }
        this.$http.post(method, this.event).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.getItems()
        }).catch(e => {
        console.log(e)
        })
     },
    }
  }
</script>